.swal-content-image-mtk {
  width: 90%;
  min-height: 100px;
  max-width: none;
}

@media (max-width: 600px) {
  .swal-content-image-mtk {
    width: 100%;
  }
}

.swal-content-html-mtk {
  width: 90%;
  min-height: 100px;
  max-width: none;
}

@media (max-width: 600px) {
  .swal-content-html-mtk {
    width: 100%;
  }
}

/* Estilos para la barra de desplazamiento */
.swal-scrollbar::-webkit-scrollbar {
  width: 8px; /* Ancho predeterminado */
}

@media (max-width: 600px) {
  .swal-scrollbar::-webkit-scrollbar {
    width: 5px;
  }
}

.swal-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.swal-scrollbar::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}
