.camera-wrapper {
  position: relative;
  width: 320px;
  height: 420px;
}

.webcam {
  position: absolute;
  top: 0;
  left: 0;
}

.bounding-box {
  position: absolute;
  top: 70px;
  left: 100px;
  width: 520px;
  height: 320px;
  border: 3px solid red;
}
